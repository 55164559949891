import React from "react";
import { useInView } from "react-intersection-observer";
import "../Team/Team.scss";

const Person = ({ data }) => {
  const imgSrc =
    data.image.src && require(`../../../../assets/images/team/${data.image.src}`);
  const screenWidth = window.innerWidth;
  const [ref, inView] = useInView({
    threshold: 0,
  });

  const HandlePerson = () => {
    if (true) {
      return inView ? "active" : "";
    }
    return "showText";
  };

  return (
    <>
      <div ref={ref} inView={inView} className={`cell ${HandlePerson()}`}>
        <img src={imgSrc} alt="" />
        <div>
          <h3>{data.name} /</h3>
          <p>{data.jobPosition}</p>
        </div>
      </div>
    </>
  );
};

export default Person;
