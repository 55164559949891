import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import i18n from "../i18n";
import { getItemFromLocalStorage } from "../localStorage";
import "./App.scss";
import Cookies from "./global/Cookies/Cookies";
import Footer from "./global/Footer/Footer";
import Menu from "./global/menu/Menu/Menu";
import HolderPortal from "./main/HolderPortal/HolderPortal";
import EvidenceTableArea from "./main/home/EvidenceTableArea/EvidenceTableArea";
import Home from "./main/home/Home/Home";
import Researches from "./main/researches/Researches/Researches";
import ResearchMain from "./main/researches/ResearchMain/ResearchMain";
import SupportPage from "./main/support/SupportPage/SupportPage";
import Team from "./main/team/Team/Team";
import Timeline from "./main/timeline/Timeline/Timeline";

function App() {
  const [language, setLanguage] = useState(window.localStorage.i18nextLng);

  useEffect(() => {
    setLanguage(i18next.language);
  }, []);

  const selectLanguage = language => {
    setLanguage(language);
    i18n.changeLanguage(language);
  };

  return (
    <>
      {getItemFromLocalStorage("cookie") ? "" : <Cookies />}
      <Menu />
      <div className="LanguageSelector">
        <p
          className={language === "pt" ? "active" : null}
          onClick={() => selectLanguage("pt")}
        >
          PT
        </p>
        <p
          className={language === "en" ? "active" : null}
          onClick={() => selectLanguage("en")}
        >
          EN
        </p>
      </div>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact={true} component={Home} />
          <Route path="/o-que-nos-move" exact={true} component={EvidenceTableArea} />
          <Route path="/nosso-percurso" component={Timeline} />
          <Route path="/pesquisas" component={Researches} />
          <Route path="/pesquisa/:id/:page" component={ResearchMain} />
          <Route path="/time" component={Team} />
          <Route path="/quero-apoiar" component={SupportPage} />
          <Route path="/portaldotitular" component={HolderPortal} />
        </Switch>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
