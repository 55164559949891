import React from "react";
import "./Donation.scss";
import { ReactComponent as Participate } from "../../../../assets/images/icons/participate.svg";
import { useTranslation } from "react-i18next";

const Donation = () => {
  const { t } = useTranslation("menu");

  return (
    <div className="Donation">
      <a href="/quero-apoiar" rel="noopener noreferrer">
        <Participate />
        {t("support")}
      </a>
    </div>
  );
};

export default Donation;
