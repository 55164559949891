import React from "react";
import "./Carousel.scss";

const Carousel = ({ researchesData, setSelected, selected, filters }) => {
  const handleClick = id => {
    setSelected(id);
  };

  const getStatus = status => {
    if (status.includes("andamento" || "progress")) {
      return "inProgress";
    } else if (status.includes("concluído" || "finished")) {
      return "finished";
    } else {
      return "funding";
    }
  };

  return (
    <section className="Carousel">
      {researchesData.map((researchData, key) => {
        const status = getStatus(researchData.status);
        return (
          <img
            className={`${selected === key ? "active" : null} ${
              filters[status] ? "filtered" : null
            }`}
            onClick={() => handleClick(key)}
            key={key}
            src={require(`../../../../assets/images/${researchData.description.image.src}`)}
            alt={`${researchData.description.image.alt}`}
          />
        );
      })}
    </section>
  );
};

export default Carousel;
