import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { longTermData, shortTermData } from "../data";
import EvidenceTableCell from "../EvidenceTableCell/EvidenceTableCell";
import "./EvidenceTableArea.scss";

const EvidenceTableArea = () => {
  const { t } = useTranslation("home");
  const [active, setActive] = useState("shortTerm");
  const translatedData = t(`tableArea.table.content.${active}`);

  const fixedData = {
    shortTerm: shortTermData,
    midTerm: longTermData
  };

  return (
    <section className="EvidenceTableArea">
      {/* <p>{t("tableArea.title")}</p> */}

      <div>
        <h2>{t("tableArea.title")}</h2>
        <p>{t("tableArea.description")}</p>
        <p>{t("tableArea.readMore")}</p>
      </div>
      <div className="table-scroll-wrapper">
        <div className="tabs">
          <p
            className={active === "shortTerm" ? "active" : null}
            onClick={() => setActive("shortTerm")}
          >
            {t("tableArea.tabs.0")}
          </p>
          <p
            className={active === "midTerm" ? "active" : null}
            onClick={() => setActive("midTerm")}
          >
            {t("tableArea.tabs.1")}
          </p>
        </div>
        <table>
          <thead>
            <tr>
              <th className="first-cell-desktop" />
              <th>{t(`tableArea.table.headings.${active}.0`)}</th>
              <th>{t(`tableArea.table.headings.${active}.1`)}</th>
              <th>{t(`tableArea.table.headings.${active}.2`)}</th>
            </tr>
          </thead>
          <tbody>
            {translatedData.map((rowData, rowIndex) => {
              return (
                <>
                  <tr className="subheader-mobile">
                    <td>{rowData.lineName}</td>
                  </tr>
                  <tr key={rowIndex}>
                    <td className="first-cell-desktop">
                      <p>{rowData.lineName}</p>
                    </td>
                    {rowData.columns.map((cellData, columnIndex) => {
                      return (
                        <EvidenceTableCell
                          evidenceNumber={
                            cellData.evidenceNumber && cellData.evidenceNumber
                          }
                          link={fixedData[active][rowIndex][columnIndex].link}
                          paragraph={cellData.paragraph}
                          imageSrc={
                            fixedData[active][rowIndex][columnIndex].image.src
                          }
                          alt={cellData.image.alt}
                        />
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default EvidenceTableArea;
