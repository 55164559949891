const headers = {
  "Content-Type": "application/json",
  Authorization: "Token ca3c162c80f2d60fc76f781a169687dd91fb6675"
};

export const apiHoldersPortalRegister = payload =>
  fetch("http://leadcollector.somas.org.br/api/leads", {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload)
  }).then((response) => {
    if (response.status === 201) {
      return response.json();
    } else {
      throw response.json();
    }
  });
