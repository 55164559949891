import { useState } from "react";
import { localizeDate } from "../helpers/dateHelper";
const cpfValidationRegex = /[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}/g;
const emailValidationRegex = /^\S+@\S+\.\S+$/;
const phoneValidationRegex = /^\(\d{2}\) \d{4,5}-\d{4}/;

export const useTextField = initialValue => {
  const [text, setText] = useState(initialValue);
  const [valid, setValid] = useState(initialValue !== "");

  const handleChange = newValue => {
    setText(newValue);
    setValid(newValue !== "");
  };
  return [text, handleChange, valid];
};

export const useCpfField = initialValue => {
  const [cpf, setCpf] = useState(initialValue);
  const [valid, setValid] = useState(cpfValidationRegex.test(initialValue));

  const handleChange = value => {
    value = value.replace(/\W/g, "");
    const mask =
      value.length > 9
        ? "$1.$2.$3-$4"
        : value.length > 6
        ? "$1.$2.$3"
        : value.length > 3
        ? "$1.$2"
        : "$1";
    value = value.replace(/(\w{1,3})(\w{0,3})(\w{0,3})(\w{0,2}).*/, mask);
    setCpf(value);
    setValid(cpfValidationRegex.test(value));
  };

  return [cpf, handleChange, valid];
};

export const useEmailField = initialValue => {
  const [email, setEmail] = useState(initialValue);
  const [valid, setValid] = useState(emailValidationRegex.test(initialValue));

  const handleChange = newValue => {
    setEmail(newValue);
    setValid(emailValidationRegex.test(newValue));
  };
  return [email, handleChange, valid];
};

export const usePhoneField = initialValue => {
  const [phone, setPhone] = useState(initialValue);
  const [valid, setValid] = useState(phoneValidationRegex.test(initialValue));

  const handleChange = value => {
    value = value.replace(/\D/g, "");

    const mask =
      value.length > 6 ? "($1) $2-$3" : value.length > 2 ? "($1) $2" : "$1";
    const groupRegex =
      value.length > 10
        ? /^(\d{1,2})(\d{0,5})(\d{0,4}).*/
        : /^(\d{1,2})(\d{0,4})(\d{0,4}).*/;

    value = value.replace(groupRegex, mask);
    setPhone(value);
    setValid(phoneValidationRegex.test(value));
  };
  return [phone, handleChange, valid];
};

export const useBirthDateInput = initialValue => {
  initialValue = localizeDate(initialValue);
  const [birthday, setBirthday] = useState(initialValue);

  const handleChange = value => {
    value = value.replace(/\D/g, "");
    const mask =
      value.length > 4 ? "$1/$2/$3" : value.length > 2 ? "$1/$2" : "$1";
    value = value.replace(/(\w{1,2})(\w{0,2})(\w{0,4}).*/, mask);
    setBirthday(value);
  };

  return [birthday, handleChange];
};

export const useCpfInput = initialValue => {
  const cpfValidationRegex = /[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}/g;
  const [cpf, setCpf] = useState(initialValue);
  const [valid, setValid] = useState(cpfValidationRegex.test(initialValue));

  const handleChange = value => {
    value = value.replace(/\W/g, "");
    const mask =
      value.length > 9
        ? "$1.$2.$3-$4"
        : value.length > 6
        ? "$1.$2.$3"
        : value.length > 3
        ? "$1.$2"
        : "$1";
    value = value.replace(/(\w{1,3})(\w{0,3})(\w{0,3})(\w{0,2}).*/, mask);
    setCpf(value);
    setValid(cpfValidationRegex.test(value));
  };

  return [cpf, handleChange, valid];
};
