import React from "react";
import "./EvidenceTableCell.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const EvidenceTableCell = ({
  evidenceNumber,
  paragraph,
  link,
  imageSrc,
  alt
}) => {
  const { t } = useTranslation("home");

  return (
    <td className="EvidenceTableCell">
      <img
        src={
          imageSrc &&
          require(`../../../../assets/images/home-table/${imageSrc}`)
        }
        alt={alt}
      />
      <p className="number">{evidenceNumber}</p>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <p className="paragraph">{paragraph}</p>
      </a>
      <a href="/pesquisas" rel="noopener noreferrer">
        <button>{t("tableArea.table.button")}</button>
      </a>
    </td>
  );
};

EvidenceTableCell.propTypes = {
  evidenceNumber: PropTypes.string,
  paragraph: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default EvidenceTableCell;
