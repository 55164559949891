export const localizeDate = (birthdate, splitChar = "-", joinChar = "/") => {
  if (birthdate && birthdate.includes(splitChar)) {
    return birthdate
      ? birthdate
          .split(splitChar)
          .reverse()
          .join(joinChar)
      : null;
  }
  return "";
};
