import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import homeEN from "./locales/en/home.json";
import menuEN from "./locales/en/menu.json";
import historyEN from "./locales/en/history.json";
import researchEN from "./locales/en/research.json";
import teamEN from "./locales/en/team.json";
import donationEN from "./locales/en/donation.json";
import homePT from "./locales/pt/home.json";
import menuPT from "./locales/pt/menu.json";
import historyPT from "./locales/pt/history.json";
import researchPT from "./locales/pt/research.json";
import teamPT from "./locales/pt/team.json";
import donationPT from "./locales/pt/donation.json";

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    interpolation: {
      escapeValue: false
    },
    fallbackLng: "pt",
    ns: ["home", "menu"],
    lng: "pt",
    lowerCaseLng: true,
    resources: {
      en: {
        home: homeEN,
        menu: menuEN,
        history: historyEN,
        research: researchEN,
        team : teamEN,
        donation: donationEN

      },
      pt: {
        home: homePT,
        menu: menuPT,
        history: historyPT,
        research: researchPT,
        team: teamPT,
        donation: donationPT
      }
    },
    returnObjects: true,
    supportedLngs: ["pt", "en"]
  })
  .then(function(t) {});

export default i18n;
