import React, { useState } from 'react';
import "./Cookies.css";

const Cookies = () => {
  const [activeCookies, setActiveCookies] = useState(false);

  const closeCookies = () => {
    setActiveCookies(true)
    window.localStorage.setItem('cookie', 'bdf9d18336b9564d3487ee2702c75bf5');
  }

  return (
    <div id="cookie-banner-lgpd" className="cookie-banner-lgpd-visible cookie-banner-lgpd-animated"
      style={{ display: `${activeCookies ? "none" : "flex"}` }}>
      <div className="cookie-banner-lgpd-container">
        <div className="cookie-banner-lgpd_text-box"><span className="cookie-banner-lgpd_text">
          Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços.
          Ao utilizar nossos serviços, você concorda com tal monitoramento. Informamos ainda que atualizamos
          nossa <a
            className="cookie-banner-lgpd-link cookie-banner-lgpd_privacy-link" target="_blank"
            style={{ color: "#0069de" }}
            href="https://mastertech.com.br/politica-de-privacidade"
            rel="noopener noreferrer">Política de Privacidade</a> <span>.</span> </span></div>
        <div className="cookie-banner-lgpd_button-box">
          <a onClick={() => closeCookies()} href="javascript:void(0);"
            className="cookie-banner-lgpd_accept-button"
            style={{ backgroundColor: "#0069de" }}
            rel="noopener noreferrer">PROSSEGUIR</a>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
