import React from "react";
import "./Research.scss";
import PropTypes from "prop-types";

const Research = ({ data }) => {
  const backgroundImage = () => {
    const bgImg =
      data.image.src && require(`../../../../assets/images/${data.image.src}`);
    return `url(${bgImg})`;
  };

  return (
    <section
      className="Research"
      style={{
        backgroundImage: backgroundImage()
      }}
    >
      <div>
        <p>{data.text}</p>
      </div>
    </section>
  );
};

Research.propTypes = {
  data: PropTypes.object.isRequired
};

export default Research;
