import React from "react";
import "./Problem.scss";
import PropTypes from "prop-types";

const Problem = ({ data }) => {
  const height = window.innerHeight;

  const backgroundImage = () => {
    const bgImg =
      data.image.src && require(`../../../../assets/images/${data.image.src}`);
    return `url(${bgImg})`;
  };

  return (
    <section className="Problem" style={{ backgroundImage: backgroundImage() }}>
      <div className="container" style={{ minHeight: height - 129 }}>
        <p>
          <span>{data.text && data.text[0]}</span>
          {data.text && data.text[1]}
        </p>
      </div>
    </section>
  );
};

Problem.propTypes = {
  data: PropTypes.object.isRequired
};

export default Problem;
