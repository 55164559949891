import React from "react";
import "./Action.scss";
import img from "../../../../assets/images/acao-risco.png";

const Action = ({ data }) => {
  const backgroundImage = () => {
    const bgImg =
      data.image.src && require(`../../../../assets/images/${data.image.src}`);
    return `url(${bgImg})`;
  };

  return (
    <section className="Action" style={{ backgroundImage: backgroundImage() }}>
      <img src={img} alt="" />
      <div className="container">
        <div>
          <p>{data.text[0]}</p>
        </div>
        <div>
          <p>{data.text[1]}</p>
        </div>
      </div>
    </section>
  );
};

export default Action;
