import PropTypes from "prop-types";
import React from "react";

import "./Loading.scss";

const Loading = ({ active }) => (
  <section className="Loading" style={{ display: active ? "flex" : "none" }}>
    <div className="lds-ripple">
      <div />
      <div />
    </div>
  </section>
);

Loading.propTypes = {
  active: PropTypes.bool.isRequired
};
export default Loading;
