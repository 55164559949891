import React from "react";
import { useTranslation } from "react-i18next";
import background from "../../../../assets/images/bgsupport.png";
import SubscriptionForm from "../FormManager/FormManager";
import "./SupportPage.scss";

const SupportPage = () => {
  const { t } = useTranslation("donation");

  return (
    <section className="SupportPage">
      <img src={background} alt="" />
      <div>
        <h3 className="top-navigation">{t("title")}</h3>
      </div>
      <article>
        <h3 dangerouslySetInnerHTML={{ __html: t("heading") }} />
        <p>{t("paragraph")}</p>
        <h2 dangerouslySetInnerHTML={{ __html: t("highlight") }} />
      </article>
      <SubscriptionForm />
      <hr />
    </section>
  );
};

export default SupportPage;
