import React, { useState } from "react";
import "./References.scss";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../../assets/images/icons/next.svg";

const References = ({ data }) => {
  const [reference, setReference] = useState(0);

  return (
    <section className="References">
      <div className="article-cut">
        <div />
        <div />
        <div>
          <p>{data[reference].font}</p>
          <h3>{data[reference].title}</h3>
          <div
            className={`authors ${
              data[reference].authors.length === 1 ? "one-author" : ""
            }`}
          >
            {data[reference].authors.map((author, key) => {
              return (
                <div key={key}>
                  <p>{author.name}</p>
                  <p>{author.from}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <article>
        <div className="selectors">
          <span
            onClick={() => {
              reference > 0 && setReference(reference - 1);
            }}
            className={reference === 0 ? "inactive" : ""}
          >
            <Arrow />
          </span>
          <span
            onClick={() => {
              reference < data.length - 1 && setReference(reference + 1);
            }}
            className={reference === data.length - 1 ? "inactive" : ""}
          >
            <Arrow />
          </span>
        </div>
        <div>
          <span />
          <span />
        </div>
        <div>
          <h3>{data[reference].title}</h3>
          <p>{data[reference].paragraph}</p>
          <Link to={{ pathname: data[reference].link }} target="_blank">
            <button>ler</button>
          </Link>
          <h4>Você também pode gostar de:</h4>
          {data.map((mapReference, key) => {
            return (
              mapReference.title !== data[reference].title && (
                <Link
                  key={key}
                  to={{ pathname: mapReference.link }}
                  target="_blank"
                >
                  {mapReference.title}
                </Link>
              )
            );
          })}
        </div>
      </article>
    </section>
  );
};

export default References;
