import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { apiRegister } from "../../../../api/formRegister";
import {
  useCpfField,
  useEmailField,
  usePhoneField,
  useTextField,
} from "../../../../hooks/FormHook";
import "./FormField.scss";

const FormField = ({ activeForm }) => {
  const { t } = useTranslation("donation");
  const dataForm = t("forms");
  const field = dataForm.category[activeForm].questions;
  const [name, setName, validName] = useTextField("");
  const [contribution, setContribution, validContribution] = useTextField("");
  const [contributionOrigin, setContributionOrigin, validContributionOrigin] = useTextField("");
  const [contributionAddress, setContributionAddress, validContributionAddress] = useTextField("");
  const [contributionMouse, setContributionMouse, validContributionMouse] = useTextField("");
  const [contributionMonitor, setContributionMonitor, validContributionMonitor] = useTextField("");
  const [contributionKeyboard, setContributionKeyboard, validContributionKeyboard] = useTextField("");

  const [cpf, setCpf, validCpf] = useCpfField("");
  const [email, setEmail, validEmail] = useEmailField("");
  const [phone, setPhone, validPhone] = usePhoneField("");
  const [birthDate, setBirthDate] = useState("");
  const [options, setOptions] = useState(true);
  const [feedback, setFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validName || validEmail) {
      const payload = {
        name: name,
        company: "",
        email: email,
        telephone: phone,
        origin: "site-somas",
        fields:
        {
          cpf,
          contribution,
          birthDate,
          options
        }
      };
      apiRegister(payload)
        .then(() => {
          setFeedback(true);
          setFeedbackMessage("cadastro efetuado com sucesso.");
        })
        .catch(() => {
          setFeedback(true);
          setFeedbackMessage(
            "erro ao enviar a mensagem, entre em contato oi@somas.org.br"
          );

          setTimeout(() => {
            setFeedback(false);
          }, 3000);
        });
    }
  };

  return (
    <>
      <form action="" onSubmit={handleSubmit} className="FormFields">
        <label htmlFor="">
          {t(`forms.category.${activeForm}.questions.name`)}
          <input
            className={!validName ? "invalid" : ""}
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        {/* {field.contactMessage && (
          <label htmlFor="">
            {t(`forms.category.${activeForm}.questions.contactMessage`)}
          </label>
        )} */}
        <label htmlFor="">
          {t(`forms.category.${activeForm}.questions.email`)}
          <input
            className={!validEmail ? "invalid" : ""}
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        {field.cpf && (
          <label htmlFor="">
            {t(`forms.category.${activeForm}.questions.cpf`)}
            <input
              className={!validCpf ? "invalid" : ""}
              type="text"
              name="cpf"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
            />
          </label>
        )}

        {field.contributionOrigin && (
          <label htmlFor="">
            {t(`forms.category.${activeForm}.questions.contributionOrigin`)}
            <input
              className={!validContributionOrigin ? "invalid" : ""}
              type="text"
              name="contributionOrigin"
              value={contributionOrigin}
              onChange={(e) => setContributionOrigin(e.target.value)}
            />
          </label>
        )}
        {field.contributionAddress && (
          <label htmlFor="">
            {t(`forms.category.${activeForm}.questions.contributionAddress`)}
            <input
              className={!validContributionAddress ? "invalid" : ""}
              type="text"
              name="contributionAddress"
              value={contributionAddress}
              onChange={(e) => setContributionAddress(e.target.value)}
            />
          </label>
        )}
        {field.warning && (
          <span class="warning">
            {t(`forms.category.${activeForm}.questions.warning`)}
          </span>
        )}

        {field.contribution && (
          <label htmlFor="">
            {t(`forms.category.${activeForm}.questions.contribution`)}
            <input
              className={!validContribution ? "invalid" : ""}
              type="text"
              name="contribution"
              value={contribution}
              onChange={(e) => setContribution(e.target.value)}
            />
          </label>
        )}
        {field.phone && (
          <label htmlFor="">
            {t(`forms.category.${activeForm}.questions.phone`)}
            <input
              className={!validPhone ? "invalid" : ""}
              type="text"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </label>
        )}
        {field.justification && (
          <span>
            {t(`forms.category.${activeForm}.questions.justification`)}
          </span>
        )}
        {field.birthDate && (
          <label htmlFor="">
            {t(`forms.category.${activeForm}.questions.birthDate`)}
            <input
              type="text"
              name="birthDate"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
            />
          </label>
        )}
        {/* 
        {field.checkboxMessage && (
          <label htmlFor="">
            {t(`forms.category.${activeForm}.questions.checkboxMessage`)}
            {field.checkboxes.map((checkbox) => {
              return (
                <Checkbox
                  label={checkbox}
                  value={options}
                  updateState={setOptions}
                />
              );
            })}
          </label>
        )} */}
      </form>
      {activeForm === "donate" ?
        <>
          <form
            className="paypal"
            action="https://www.paypal.com/cgi-bin/webscr"
            method="post"
            target="_top"
          >
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input
              type="hidden"
              name="hosted_button_id"
              value="2QXU5X8UCD6MG"
            />
            <input
              type="image"
              src="https://mastertechfiles.s3-sa-east-1.amazonaws.com/somas-button/btn-paypal.png"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Faça doações com o botão do PayPal"
            />
            <img alt="" border="0" src="https://www.paypal.com/pt_BR/i/scr/pixel.gif" width="1" height="1" />
          </form>
        </>
        :
        <>
          <button className="button-form" type="submit" onClick={handleSubmit}>
            Enviar
          </button>
          <span className={feedback ? "feedback" : ""}>{feedbackMessage}</span>
        </>
      }
    </>
  );
};

export default FormField;
