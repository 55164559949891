export const shortTermData = [
  [
    {
      image: {
        src: "Habilidades-ensinoInf-curto.png"
      },
      link:
        "http://download.basenacionalcomum.mec.gov.br/"
    },
    {
      image: {
        src: "Habilidades-ensinoFund-curto.png"
      },
      link:
        "http://download.basenacionalcomum.mec.gov.br/"
    },
    {
      image: {
        src: "Habilidades-ensinoMed-curto.png"
      },
      link:
        "http://download.basenacionalcomum.mec.gov.br/"
    }
  ],
  [
    {
      image: {
        src: "RecursosMat-ensinoInf-curto.png"
      },
      link:
        "https://www.qedu.org.br/brasil/censo-escolar?year=2018&dependence=0&localization=0&item="
    },
    {
      image: {
        src: "RecursosMat-ensinoFund-curto.png"
      },
      link:
        "https://www.qedu.org.br/brasil/censo-escolar?year%3D2018%26dependence%3D0%26localization%3D0%26item%3D&sa=D&ust=1594166610819000&usg=AFQjCNG_KxA-Ls9ibMco9EeEzxMekivWPw"
    },
    {
      image: {
        src: "RecursosMat-ensinoMed-curto.png"
      },
      link:
        "https://www.qedu.org.br/brasil/censo-escolar?year%3D2018%26dependence%3D0%26localization%3D0%26item%3D&sa=D&ust=1594166610819000&usg=AFQjCNG_KxA-Ls9ibMco9EeEzxMekivWPw"
    }
  ],
  [
    {
      image: {
        src: "PratDecPed-ensinoInf-curto.png"
      },
      link:
        "https://www.qedu.org.br/brasil/censo-escolar?year%3D2018%26dependence%3D0%26localization%3D0%26item%3D&sa=D&ust=1594166610818000&usg=AFQjCNEpC4y5pFSVcwZUsXa6_AEjyxTx5A"
    },
    {
      image: {
        src: "Conexao-ensinoFund-curto.png"
      },
      link:
        "https://www.qedu.org.br/brasil/censo-escolar?year%3D2018%26dependence%3D0%26localization%3D0%26item%3D&sa=D&ust=1594166610818000&usg=AFQjCNEpC4y5pFSVcwZUsXa6_AEjyxTx5A"
    },
    {
      image: {
        src: "Conexao-EnsinoMed-curto.png"
      },
      link:
        "https://www.qedu.org.br/brasil/censo-escolar?year%3D2018%26dependence%3D0%26localization%3D0%26item%3D&sa=D&ust=1594166610818000&usg=AFQjCNEpC4y5pFSVcwZUsXa6_AEjyxTx5A"
    }
  ],
  [
    {
      image: {
        src: "pratDecPedag02.png"
      },
      link:
        "https://curriculo.cieb.net.br/&sa=D&ust=1594166610820000&usg=AFQjCNF7SrCNBfbCnJ6NyOMzhH8cVuO7sw"
    },
    {
      image: {
        src: "pratDecPedag03.png"
      },
      link:
        "https://curriculo.cieb.net.br/&sa=D&ust=1594166610820000&usg=AFQjCNF7SrCNBfbCnJ6NyOMzhH8cVuO7sw"
    },
    {
      image: {
        src: "RecMat-formcDeProf-med.png"
      },
      link:
        "http://fundacaotelefonica.org.br/acervo/um-guia-para-construcao-do-pensamento-computacional/&sa=D&ust=1594166610829000&usg=AFQjCNG-1giH0nrqsUdclbvgvE1SwJdgSw"
    }
  ]
];

export const longTermData = [
  [
    {
      image: {
        src: "Habilidades-ensinoInf-curto.png"
      },
      link:
        "https://www.qedu.org.br/brasil/pessoas/professor&sa=D&ust=1594166610803000&usg=AFQjCNFa7-sxhAk4BkxyFh1owIxwjdjSlA"
    },
    {
      image: {
        src: "Habilidades-AcessoAoM-medio.png"
      },
      link:
        "https://brasscom.org.br/amplifying-human-potential-infosys/&sa=D&ust=1594166610832000&usg=AFQjCNG9_kG5OK7wZlAMssSv0dGgg1ktow"
    },
    {
      image: {
        src: "Habilidades-ensinoMed-curto.png"
      },
      link:
        "http://www.brasilmaisti.com.br/&sa=D&ust=1594166610832000&usg=AFQjCNFGjTxYzHzx7pPhRjU02XKDMmUHcw"
    }
  ],
  [
    {
      image: {
        src: "recursos-materiais-01.png"
      },
      link:
        "https://www.qedu.org.br/brasil/pessoas/professor&sa=D&ust=1594166610803000&usg=AFQjCNFa7-sxhAk4BkxyFh1owIxwjdjSlA"
    },
    {
      image: {
        src: "recursos-materiais-02.png"
      },
      link:
        "https://www.cetic.br/media/analises/tic_domicilios_2018_coletiva_de_imprensa.pdf"
    },
    {
      image: {
        src: "recursos-materiais-03.png"
      },
      link:
        "http://www.planejamento.gov.br/cidadania-digital/brasil-eficiente-cidadania-digital/pesquisa-de-gestao-da-qualidade-em-servicos-publicos"
    }
  ],
  [
    {
      image: {
        src: "Conexao-formacDeProf-med.png"
      },
      link: "https://www.qedu.org.br/brasil/pessoas/professor"
    },
    {
      image: {
        src: "conexao-acessoAoM-med.png"
      },
      link: "http://data.cetic.br/cetic/explore?idPesquisa=TIC_DOM"
    },
    {
      image: {
        src: "Conexao-ensinoFund-curto.png"
      },
      link:
        "http://www.planejamento.gov.br/cidadania-digital/brasil-eficiente-cidadania-digital/pesquisa-de-gestao-da-qualidade-em-servicos-publicos"
    }
  ],
  [
    {
      image: {
        src: "PratDecPed-ensinoInf-curto.png"
      },
      link:
        "http://portal.mec.gov.br/docman/setembro-2019/124721-texto-referencia-formacao-de-professores/file"
    },
    {
      image: {
        src: "pratDecPedag03.png"
      },
      link: "https://reprograma.com.br/integral.html"
    },
    {
      image: {
        src: "RecMat-formcDeProf-med.png"
      },
      link:
        "http://www.planejamento.gov.br/cidadania-digital/brasil-eficiente-cidadania-digital/pesquisa-de-gestao-da-qualidade-em-servicos-publicos"
    }
  ]
];
