import React from "react";
import PropTypes from "prop-types";

import "./Select.scss";

const Select = ({
  children,
  displayError,
  label,
  value,
  updateState,
  valid,
  visible
}) => {
  const handleChange = e => {
    updateState(e.target.value);
  };

  return (
    <div style={{ display: visible ? "flex" : "none" }} className="Select">
      {label && <label>{label}</label>}

      <select
        className={valid && displayError ? "show-error" : ""}
        value={value}
        onChange={handleChange}
      >
        {children}
      </select>
    </div>
  );
};

Select.propTypes = {
  children: PropTypes.any,
  displayError: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  updateState: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  visible: PropTypes.bool
};

Select.defaultProps = {
  visible: true
};

export default Select;
