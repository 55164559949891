import React from "react";
import "./Footer.scss";
import { ReactComponent as Ig } from "../../../assets/images/icons/ig.svg";
import { ReactComponent as Location } from "../../../assets/images/icons/location.svg";
import { ReactComponent as Mail } from "../../../assets/images/icons/mail.svg";
import certificate from "../../../assets/images/icons/certificate.png";

const Footer = () => {
  return (
    <footer className="Footer">
      <h1>SOMAS</h1>
      <div>
        <div>
          <Location />
          <div>
            <p>Av. Brigadeiro Luis Antônio, 2696, Jardim Paulista</p>
            <p>São Paulo - SP 01402-000</p>
          </div>
        </div>
        <div>
          <a href="https://www.instagram.com/somas.org.br/" rel="noopener noreferrer"><Ig /></a>
          <div>
            <p>somas.org.br</p>
          </div>
        </div>
        <div>
          <a href="mailto:contato@somas.org.br"><Mail /></a>
          <div>
            <a href="mailto:contato@somas.org.br">contato@somas.org.br</a>
          </div>
        </div>
        <div>
          <a href="https://somas.org.br/portaldotitular" rel="noopener noreferrer">Portal do titular</a>
        </div>
      </div>
      <div className="certificate">
        <a href="https://complianceforbusiness.com.br/selo-pro-data-somas/" target="_blank">
          <img alt="Imagem de certificação LGPD" src={certificate} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
