import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Apoio } from "../../../../assets/images/icons/apoio.svg";
import { ReactComponent as Blog } from "../../../../assets/images/icons/blog.svg";
import { ReactComponent as Historia } from "../../../../assets/images/icons/historia.svg";
import { ReactComponent as Home } from "../../../../assets/images/icons/home.svg";
import { ReactComponent as Pesquisa } from "../../../../assets/images/icons/pesquisa.svg";
import { ReactComponent as Time } from "../../../../assets/images/icons/time.svg";
import { ReactComponent as Trust } from "../../../../assets/images/icons/Trust.svg";
import { ReactComponent as Groups } from "../../../../assets/images/icons/Groups.svg";
import Donation from "../Donation/Donation";
import "./Menu.scss";

const Menu = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { t } = useTranslation("menu");

  const toggleMenu = () => setShowSidebar(!showSidebar);

  return (
    <nav className="SideMenu">
      <input id="burger-menu" type="checkbox" onClick={toggleMenu} />
      <label htmlFor="burger-menu">
        <div className={`menu${showSidebar ? " active" : ""}`}>
          <span className="burger" />
        </div>
      </label>
      <div className={`menu-items${showSidebar ? " active" : ""}`}>
        <div className="menu-icons">
          {/* <Icons /> */}
          <div className="items">
            <a href="/" rel="noopener noreferrer">
              <Home />

              {t("home")}
            </a>
            <a href="/o-que-nos-move" rel="noopener noreferrer">
              <Blog />

              {t("ourMove")}
            </a>
            <a href="/nosso-percurso" rel="noopener noreferrer">

              <Historia />
              {t("ourHistory")}
            </a>
            <a href="/time" rel="noopener noreferrer">
              <Groups />
              {t("ourTeam")}
            </a>
            <a href="/pesquisas" rel="noopener noreferrer">
              <Pesquisa />
              {t("researches")}
            </a>
            <a href="/quero-apoiar" rel="noopener noreferrer">
              <Trust className="Trust" />
              {t("support")}
            </a>
            {/* <a
              href="http://blog.somas.org.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog <span> {t("blog")}</span>
            </a> */}
          </div>
        </div>
        <Donation />
      </div>
    </nav>
  );
};

export default Menu;
