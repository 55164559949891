import React from "react";
import { useTranslation } from "react-i18next";
import background from "../../../../assets/images/Home.png";
import Timeline from "../../timeline/Timeline/Timeline";
import "./Home.scss";

const Home = () => {
  const { t } = useTranslation("home");
  const texts = t('ITexts', { returnObjects: true });
  return (
    <section className="Home">
      <header>
        <img src={background} alt="imagem de fundo" />
        <h1>SOMAS</h1>
      </header>
      <div className="heading-container">
        <h3 dangerouslySetInnerHTML={{ __html: t("heading") }} />
        <h5>{t("subHeading.0")}</h5>
        <h5>{t("subHeading.1")}</h5>
      </div>
      <div className="timeline-container">
        {/* <Timeline /> */}
      </div>
      {/* INFO SOMAS */}
      <div className="somas-container">
        {texts.map((section, index) => (
          <div key={index} className="somas-section">
            <h2 dangerouslySetInnerHTML={{ __html: section.title }}></h2>
            {section.subtitle && <h3>{section.subtitle}</h3>}
            <p dangerouslySetInnerHTML={{ __html: section.description }}></p>
          </div>
        ))}
      </div>
      {/* <EvidenceTableArea /> */}
      <section className="last-section">
        <p>{t("lastSection")}</p>
      </section>
    </section>
  );
};

export default Home;
