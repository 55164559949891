import React from "react";
import { useTranslation } from "react-i18next";
import "./Timeline.scss";

const Timeline = () => {
  const { t } = useTranslation("history");
  const time = t("history");
  const buttons = t("buttons");

  return (
    <section className="Timeline">
      <div>
        <h2>{t("title")}</h2>
      </div>
      <div className="text-timeline">
        <h3>
          {t("related").textOne}
        </h3>

        <h3>
          {t("related").textTwo}
        </h3>
      </div>
      {/* <div className="items">
        {time.map((year) => {
          return <Year time={year} buttons={buttons}/>
        })}
      </div> */}
    </section>
  );
};

export default Timeline;
