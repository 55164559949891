import React from "react";
import "./ResearchMain.scss";
import { Switch, Route, useParams, Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Problem from "../Problem/Problem";
import { ReactComponent as BackButton } from "../../../../assets/images/icons/back.svg";
import Research from "../Research/Research";
import Action from "../Action/Action";
import References from "../References/References";

const ResearchMain = () => {
  const { t } = useTranslation("research");
  const { id, page } = useParams();
  const data = t(`researches.${id}`);

  return (
    <>
      <nav className="research-nav">
        <div>
          <NavLink to={`/pesquisa/${id}/problema`}>
            <p className={page === "problema" ? "active" : null}>
              {t("nav.problem")}
            </p>
          </NavLink>
          <NavLink to={`/pesquisa/${id}/pesquisa`}>
            <p className={page === "pesquisa" ? "active" : null}>
              {t("nav.research")}
            </p>
          </NavLink>
          <NavLink to={`/pesquisa/${id}/acao`}>
            <p className={page === "acao" ? "active" : null}>
              {t("nav.action")}
            </p>
          </NavLink>
          <NavLink to={`/pesquisa/${id}/referencias`}>
            <p className={page === "referencias" ? "active" : null}>
              {t("nav.references")}
            </p>
          </NavLink>
        </div>
      </nav>
      <div className="path">
        <Link to="/pesquisas">
          <BackButton />
        </Link>
        <article>
          <p>{t("path")}</p>
          <p>{">"}</p>
          <p>{t(`researches.${id}.researchName`)}</p>
        </article>
      </div>
      <Switch>
        <Route
          path={`/pesquisa/${id}/problema`}
          component={() => <Problem data={data.problem} />}
        />{" "}
        <Route
          path={`/pesquisa/${id}/pesquisa`}
          component={() => <Research data={data.research} />}
        />
        <Route
          path={`/pesquisa/${id}/acao`}
          component={() => <Action data={data.action} />}
        />
        <Route
          path={`/pesquisa/${id}/referencias`}
          component={() => <References data={data.references} />}
        />
      </Switch>
    </>
  );
};

export default ResearchMain;
